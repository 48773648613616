<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <rect
      x="0.004"
      y="294.452"
      style="fill:#74CBB4;"
      width="511.996"
      height="144.807"
    />
    <rect y="72.743" style="fill:#82DCC7;" width="511.996" height="250.281" />
    <g>
      <path
        style="fill:#74CBB4;"
        d="M53.995,323.022H0v-53.995C29.818,269.027,53.995,293.191,53.995,323.022z"
      />
      <path
        style="fill:#74CBB4;"
        d="M0,72.741h53.995c0,29.818-24.177,53.995-53.995,53.995V72.741z"
      />
      <path
        style="fill:#74CBB4;"
        d="M511.996,72.741v53.995c-29.83,0-53.995-24.177-53.995-53.995
		C458.002,72.741,511.996,72.741,511.996,72.741z"
      />
      <path
        style="fill:#74CBB4;"
        d="M511.996,269.027v53.995h-53.995C458.002,293.191,482.166,269.027,511.996,269.027z"
      />
      <ellipse
        style="fill:#74CBB4;"
        cx="255.998"
        cy="197.871"
        rx="67.97"
        ry="85.756"
      />
      <rect
        x="357.635"
        y="175.003"
        style="fill:#74CBB4;"
        width="123.235"
        height="19.057"
      />
      <rect
        x="26.044"
        y="175.003"
        style="fill:#74CBB4;"
        width="123.235"
        height="19.057"
      />
      <rect
        x="404.642"
        y="210.894"
        style="fill:#74CBB4;"
        width="76.228"
        height="19.057"
      />
      <rect
        x="26.044"
        y="210.894"
        style="fill:#74CBB4;"
        width="76.228"
        height="19.057"
      />
    </g>
    <g>
      <rect
        x="0.004"
        y="390.982"
        style="fill:#82DCC7;"
        width="511.996"
        height="19.057"
      />
      <rect
        x="0.004"
        y="352.232"
        style="fill:#82DCC7;"
        width="511.996"
        height="19.057"
      />
      <polygon
        style="fill:#82DCC7;"
        points="246.47,170.42 265.527,170.42 296.024,170.42 296.024,151.363 265.527,151.363 
		265.527,130.228 246.47,130.228 246.47,151.363 215.972,151.363 215.972,207.406 276.967,207.406 276.967,225.335 265.527,225.335 
		246.47,225.335 215.972,225.335 215.972,244.392 246.47,244.392 246.47,265.527 265.527,265.527 265.527,244.392 296.024,244.392 
		296.024,188.349 235.029,188.349 235.029,170.42 	"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
